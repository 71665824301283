<ng-container *transloco="let t; read: 'reading-list-item'">
  <div class="d-flex flex-row g-0 mb-2 reading-list-item">
    <div class="pe-2">
      <app-image width="106px" [styles]="{'max-height': '125px'}" class="img-top me-3" [imageUrl]="imageService.getChapterCoverImage(item.chapterId)"></app-image>
      @if (item.pagesRead === 0 && item.pagesTotal > 0) {
        <div class="not-read-badge" ></div>
      }
      @if (item.pagesRead < item.pagesTotal && item.pagesTotal > 0 && item.pagesRead !== item.pagesTotal) {
        <div class="progress-banner">
          <p><ngb-progressbar type="primary" height="5px" [value]="item.pagesRead" [max]="item.pagesTotal"></ngb-progressbar></p>
        </div>
      }
    </div>

    <div class="flex-grow-1">
      <div class="g-0">
        <h5 class="mb-1 pb-0" id="item.id--{{position}}">
          {{item.title}}
          <div class="float-end">
            <button class="btn btn-danger" (click)="remove.emit(item)">
                <span>
                    <i class="fa fa-trash me-1" aria-hidden="true"></i>
                </span>
              <span class="d-none d-sm-inline-block">{{t('remove')}}</span>
            </button>
            <button class="btn btn-primary ms-2" (click)="readChapter(item)">
                <span>
                    <i class="fa fa-book me-1" aria-hidden="true"></i>
                </span>
              <span class="d-none d-sm-inline-block">{{t('read')}}</span>
            </button>
          </div>

        </h5>
        <div class="ps-1 d-none d-md-inline-block">
          <app-series-format [format]="item.seriesFormat"></app-series-format>
          <a href="/library/{{item.libraryId}}/series/{{item.seriesId}}">{{item.seriesName}}</a>
        </div>

        <!-- TODO: Let's add summary here-->

        @if (item.releaseDate !== '0001-01-01T00:00:00') {
          <div class="ps-1 mt-2">
            Released: {{item.releaseDate | date:'longDate'}}
          </div>
        }
      </div>
    </div>

  </div>
</ng-container>
